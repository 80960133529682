// IE11 polyfills
import 'promise-polyfill/src/polyfill';
import objectFitImages from 'object-fit-images';
/* To make Ant Design Collapse work on IE11
* https://babeljs.io/docs/en/next/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env */
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// Import our CSS
import styles from '../css/app.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const axios = await import( /* webpackChunkName: "axios" */ 'axios');
    
    Vue.use(VueCookies);
    Vue.prototype.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        
        components: {
            'accordion-comp': () => import(/* webpackChunkName: "accordion-comp" */ '../vue/AccordionComp.vue')
        },
        
        data: {
            mobileNavIsOpen: false,
    
            // News page
            page: 1,
            articlesLoadedCount: 0,
            totalCount: 0,
            initialCount: 0,
            sequentialCount: 0,
            loadingEntries: false,
            allLoaded: false,
            
            // Navigation
            mastheadHeight: 0,
            fixateNav: false
        },
    
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
    
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
        },
    
        mounted() {
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            // Navigation
            let masthead = document.getElementById('masthead');
            this.mastheadHeight = masthead.clientHeight;
            this.onWindowScroll();
            
            // News
            if (this.$refs.newsEntries) {
                this.totalCount = parseInt(this.$refs.newsEntries.dataset.totalCount, 10);
                this.initialCount = parseInt(this.$refs.newsEntries.dataset.initialCount, 10);
                this.sequentialCount = parseInt(this.$refs.newsEntries.dataset.sequentialCount, 10);
            }
            this.articlesLoadedCount = this.initialCount;
            this.allLoaded = this.totalCount <= this.articlesLoadedCount;
        },
        
        methods: {
            onWindowScroll() {
                if (!this.mobileNavIsOpen) {
                    this.fixateNav = window.pageYOffset > 20;
                }
            },
            
            openNav(){
                return this.mobileNavIsOpen = !this.mobileNavIsOpen;
            },
    
            loadMoreArticles() {
                let vm = this;
        
                // Load more btn was clicked so we're loading entries and we're hiding the button and showing the loader
                this.loadingEntries = true;
    
                axios.get("ajax/news/p" + this.page + "?initialCount=" + this.initialCount + "&sequentialCount=" + this.sequentialCount)
                    .then(function (response) {
                        // Get element to append articles to
                        let parent = vm.$refs.newsEntries;
                
                        // Append loaded articles at the end of the element
                        parent.insertAdjacentHTML('beforeend', response.data);
                        
                        // Set next pagination page
                        vm.page++;
                
                        // Update our article loaded count
                        vm.articlesLoadedCount += vm.sequentialCount;
                
                        // Show the load more articles button again
                        vm.loadingEntries = false;
                
                        // Fade in new elements (set timeout because else it doesn't work properly)
                        setTimeout(function () {
                            for (const el of Array.from(document.getElementsByClassName('newly-loaded'))) {
                                el.classList.remove("opacity-0");
                            }
                        }, 100);
                
                
                        // Check if all articles are loaded to hide the load more button
                        if (vm.articlesLoadedCount >= vm.totalCount) {
                            vm.allLoaded = true;
                        }
                    })
                    .catch(function (error) {
                        //console.log("error fetching articles", error);
                    });
            }
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {
    // IE11 object-fit polyfill
    objectFitImages();
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
